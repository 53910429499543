@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_home {
    .media {
        @include breakpoint(large down) {
            position: relative;
        }

        picture {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba($color-black, 0.42);
                position: absolute;
                display: block;
            }

            @include breakpoint(xlarge) {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;

                img {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    object-fit: cover;
                }
            }
        }

        .video-holder {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;

            video {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                object-fit: cover;
            }
        }
    }

    .content {
        z-index: 1;
        margin-top: 24px;
        margin-bottom: 24px;

        @include breakpoint(xlarge) {
            margin-top: 352px;
            margin-bottom: 282px;
        }

        @include breakpoint(xlarge) {
            p {
                color: $color-white;
            }
        }

        .button {
            margin-top: 8px;

            @include breakpoint(small only) {
                width: 100%;
            }

            @include breakpoint(xlarge) {
                margin-top: 32px;
            }
        }
    }

    .heading-h1 {
        color: $color-white;

        @include breakpoint(large down) {
            position: absolute;
            left: 16px;
            bottom: 24px;
            width: calc(100% - 32px);
        }

        @include breakpoint(xlarge) {
            margin-bottom: 48px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:126";