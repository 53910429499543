//Theme variables
$theme-name: 'basic';

//Defaults
$default-size: 16px;

//Hard colors
$color-black: #000000;
$color-white: #FFFFFF;

//Colors
$color-primary: $color-white;
$color-secondary: $color-black;
$color-tertiary: #EC672D;

//Black variants
$color-black-75: #696868;
$color-black-50: #9D9C9B;
$color-black-15: #E2E2E2;

//Gold variants
$color-gold-primary: #F2C583;
$color-gold-secondary: #987F66;
$color-gold-tertiary: #815832;
$color-gold-tertiary-two: #885F37;
$color-gold-tertiary-three: #F5F2F0;
$color-gold-quaternary: #F2C989;
$color-gold-quaternary-two: #FDD491;

$color-error: $color-tertiary;
$color-form-borders: #CCCCCC;
$color-menu-bg: #F0F0F0;

//Text colors
$color-text: $color-black-75;

//Gradients
$gradient-primary: linear-gradient(270deg, $color-gold-primary 78.52%, $color-gold-secondary 100%);
$gradient-secondary: linear-gradient(180deg, $color-gold-secondary 0%, $color-gold-primary 100%);
$gradient-secondary-two: linear-gradient(90deg, $color-gold-secondary 0%, $color-gold-primary 100.14%);
$gradient-tertiary: linear-gradient(77deg, $color-gold-tertiary 18.05%, $color-gold-tertiary-two 23.94%, $color-gold-quaternary 74.98%, $color-gold-quaternary-two 82.83%);

//Font variables
$mainfont: "Montserrat", serif;

//Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

//Headings
$heading-h1-max: ceil(($default-size * 6)); // ~96px
$heading-h1-min: ceil(($default-size * 2.75)); // ~44px
$heading-h2-max: ceil(($default-size * 4)); // ~64px
$heading-h2-min: ceil(($default-size * 2.25)); // ~36px
$heading-h3-max: ceil(($default-size * 3)); // ~48px
$heading-h3-min: ceil(($default-size * 2)); // ~32px
$heading-h4-max: ceil(($default-size * 2)); // ~32px
$heading-h4-min: ceil(($default-size * 1.5)); // ~24px
$heading-h5-max: ceil(($default-size * 1.5)); // ~24px
$heading-h5-min: ceil(($default-size * 1.25)); // ~20px
$heading-h6-max: ceil(($default-size * 1.25)); // ~20px
$heading-h6-min: ceil(($default-size * 1.125)); // ~18px

//Text
$font-l-max: ceil(($default-size * 1.5)); // ~24px
$font-l-min: ceil(($default-size * 1.25)); // ~20px
$font-m-max: ceil(($default-size * 1.375)); // ~22px
$font-m-min: ceil(($default-size * 1.125)); // ~18px
$font-s: ceil(($default-size * 1.125)); // ~18px
$font-xs: ceil(($default-size * 1)); // ~16px
$font-xxs: ceil(($default-size * 0.875)); // ~14px
$font-menu: ceil(($default-size * 1)); // ~16px
$font-form: ceil(($default-size * 1.125)); // ~18px

//Other text styles
$font-quote-max: ceil(($default-size * 2)); // ~32px
$font-quote-min: ceil(($default-size * 1.625)); // ~26px

//Font settings
$text-settings: (
        'font-heading-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 4vw, $heading-h1-max),
                font-weight: $light,
                line-height: 100%,
        ),
        'font-heading-h2': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 4vw, $heading-h2-max),
                font-weight: $regular,
                line-height: 100%,
        ),
        'font-heading-h3': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 4vw, $heading-h3-max),
                font-weight: $regular,
                line-height: 100%,
        ),
        'font-heading-h4': (
                font-family: $mainfont,
                font-size: clamp($heading-h4-min, 4vw, $heading-h4-max),
                font-weight: $bold,
                line-height: 100%,
        ),
        'font-heading-h5': (
                font-family: $mainfont,
                font-size: clamp($heading-h5-min, 4vw, $heading-h5-max),
                font-weight: $semibold,
                line-height: 100%,
        ),
        'font-heading-h6': (
                font-family: $mainfont,
                font-size: clamp($heading-h6-min, 4vw, $heading-h6-max),
                font-weight: $semibold,
                line-height: 100%,
        ),
        'font-text-l': (
                font-family: $mainfont,
                font-size: clamp($font-l-min, 4vw, $font-l-max),
                line-height: 150%,
        ),
        'font-text-m': (
                font-family: $mainfont,
                font-size: clamp($font-m-min, 4vw, $font-m-max),
                line-height: 150%,
        ),
        'font-text-s': (
                font-family: $mainfont,
                font-size: $font-s,
                line-height: 150%,
        ),
        'font-text-xs': (
                font-family: $mainfont,
                font-size: $font-xs,
                line-height: 150%,
        ),
        'font-text-xxs': (
                font-family: $mainfont,
                font-size: $font-xxs,
                line-height: 150%,
        ),
        'font-quote': (
                font-family: $mainfont,
                font-size: clamp($font-quote-min, 4vw, $font-quote-max),
                line-height: 125%,
        )
);

;@import "sass-embedded-legacy-load-done:29";